<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Orchestration Manager
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">Manage containers deployed next to the Core Container (e.g. Data Apps) in the Kubernetes cluster.</p>
          <p>This component is used by the <router-link to="/orchestration/workflow">Workflow Manager</router-link> to orchestrate containers.</p>
        </em>
      </card-component>
      <card-component title="Running Containers" icon="chart-sankey-variant" header-icon="reload" @header-icon-click="getContainers">
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="id"
          default-sort-direction="desc"
          :data="containers"
        >
          <b-table-column label="Container Name" field="id" sortable v-slot="props">
            {{ props.row.containerInfo.containerModel.name }}
          </b-table-column>
          <b-table-column label="image" field="id" sortable v-slot="props">
            {{ props.row.containerInfo.containerModel.image.name }}
          </b-table-column>
          <b-table-column label="Healthy" field="id" sortable v-slot="props">
            {{ props.row.healthy }}
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="stopContainer (props.row.containerInfo.containerModel.name)"
            >
            </b-icon>
            <b-icon
              icon="file-search"
              type="is-info"
              class="is-clickable"
              @click.native="showContainerModal(props.row.containerInfo.containerModel.name)"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No running containers</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <card-component title="Start new Container" icon="file-document-edit" headerColor="info" class="tile is-child">
        <form @submit.prevent="startContainer">
          <b-field label="Container Image name" horizontal>
            <b-input
              v-model="newContainer.image.name"
              type="text"
              rows="1"
              required
            />
            </b-field>
            <b-field label="Container image tag" horizontal>
            <b-input
              v-model="newContainer.image.tag"
              type="text"
              rows="1"
            />
          </b-field>
          <b-field label="Container name" horizontal>
            <b-input
              v-model="newContainer.name"
              type="text"
              rows="1"
              required
            />
          </b-field>
          <b-field label="Additional configuration" horizontal>
            <b-input
              v-model="newContainer.configuration[0].content"
              type="textarea"
            />
          </b-field>
          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Start Container</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-primary is-outlined" @click="()=>{newContainer = { image: '', name: ''}}"
                  >Reset</b-button
                >
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
      <b-modal :active.sync="containerModal.isActive" has-modal-card>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Container details</p>
          </header>
          <section class="modal-card-body" v-if="containerModal.containerWithStatus">
            <b-field label="Container name" horizontal>
              <p class="control">
                  {{ containerModal.containerWithStatus.containerInfo.containerModel.name }}
              </p>
            </b-field>
             <b-field label="Container image" horizontal>
              <p class="control">
                  {{ containerModal.containerWithStatus.containerInfo.containerModel.image.name }}
              </p>
            </b-field>
            <b-field label="Healthy" horizontal>
              <p class="control">
                  {{ containerModal.containerWithStatus.healthy }}
              </p>
            </b-field>
            <b-field label="Environment variables" horizontal>
              <p class="control has-text-success">
                  {{ containerModal.containerWithStatus.containerInfo.containerModel.environment }}
              </p>
            </b-field>
            <b-field label="Subject " horizontal>
              <b-select v-model="containerModal.selectedConfiguration" placeholder="Select configuration">
                  <option
                    v-for="configuration in containerModal.containerWithStatus.containerInfo.containerModel.configuration"
                    :value="configuration.content"
                    :key="configuration.filename">
                    {{ configuration.filename }}
                </option>
              </b-select>
            </b-field>
            <b-field v-if="containerModal.selectedConfiguration" label="Configuration" horizontal>
              <pre class="control">
{{ containerModal.selectedConfiguration }}
              </pre>
            </b-field>
          </section>
          <footer class="modal-card-foot">
              <button class="button" type="button" @click="containerModal.isActive = false">Close</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'
export default {
  name: 'OrchestrationManager',
  components: {
    HeroBar,
    CardComponent,
    TitleBar
  },
  data () {
    return {
      containerModal: {
        isActive: false,
        containerWithStatus: null,
        selectedConfiguration: null
      },
      containers: [],
      isLoading: false,
      paginated: true,
      newContainer: {
        image: {
          name: 'nginx',
          tag: 'latest'
        },
        configuration: [
          {
            filename: 'application.yaml',
            content: ''
          }
        ],
        name: '',
        ports: ['8080']
      }
    }
  },
  computed: {
    titleStack () {
      return ['Orchestration', 'Orchestration Manager']
    },
    ...mapState(['api'])
  },
  async created () {
    this.orchestrationInterval = setInterval(this.getContainers, 30000)
    await this.getContainers()
  },
  methods: {
    async getContainers () {
      try {
        const containers = await this.api.get('/orchestration')
        this.containers = containers.data
      } catch (e) {
        clearInterval(this.orchestrationInterval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.orchestrationInterval)
            this.getContainers()
            this.orchestrationInterval = setInterval(this.getContainers, 30000)
          }
        })
        console.log(e)
      }
    },
    async startContainer () {
      try {
        await this.api.post('/orchestration', this.newContainer, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        this.$buefy.toast.open({
          message: 'Successfully started the Container',
          type: 'is-success',
          position: 'is-bottom'
        })
        await this.getContainers()
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async stopContainer (containerName) {
      this.$buefy.dialog.confirm({
        title: 'Delete resource',
        message: `Are you sure you want to delete the container with name ${containerName}?`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            console.log(`/orchestration/${encodeURIComponent(containerName)}`)
            await this.api.delete(`/orchestration/${encodeURIComponent(containerName)}`)
            await this.getContainers()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },
    async showContainerModal (containerName) {
      try {
        const container = await this.api.get(`/orchestration/${encodeURIComponent(containerName)}`)
        this.containerModal = {
          isActive: true,
          containerWithStatus: container.data
        }
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    }
  }
}
</script>
